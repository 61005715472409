import { CSSProperties, useCallback, useMemo } from "react";
import styles from "./button.module.css";

interface IButtonProps {
	css?: CSSProperties;
	disabled?: boolean;
	isLoading?: boolean;
	minWidth?: number;
	onPress?: () => void;
	text: string;
}

export function Button({
	css,
	disabled,
	isLoading,
	minWidth,
	onPress,
	text,
}: IButtonProps) {
	const handleClick = useCallback(() => {
		if (!disabled && onPress) onPress();
	}, [disabled, onPress]);

	const buttonStyle: CSSProperties = useMemo(() => {
		return {
			...(css ?? {}),
			minWidth: minWidth ?? "auto",
		};
	}, [css, minWidth]);

	return (
		<button
			className={`${styles.button} ${styles.ripple} ${
				isLoading ? styles.loading_button : ""
			} inter600 sp16`}
			disabled={disabled}
			onClick={handleClick}
			style={buttonStyle}
		>
			<img
				src="/90-ring.svg"
				alt="loading indicator"
				width={20}
				height={20}
				className={styles.loading_indicator}
				style={{ opacity: isLoading ? 1 : 0 }}
			/>
			<span style={{ opacity: isLoading ? 0 : 1 }}>{text}</span>
		</button>
	);
}
